export const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const formartTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    
    if (time < 60) {
        return str_pad_left(seconds,'0',2) + " seg";
    } else {
        return str_pad_left(minutes,'0',2)+' min '+str_pad_left(seconds,'0',2) + " seg";
    }
}

function str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
}