import axios from 'axios';
import { SERVER_URL } from '../constant';

const api = axios.create({
  baseURL: SERVER_URL,
});

api.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('auth'));
  const token = user?.token;
  if(token){
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
    config.crossdomain = true;
  }
  return config;
});

export default api;