import axios from 'axios';
import {SERVER_URL} from '../../constant';
import api from '../../api/interceptor';

export const addAdminAction = (addData, callback, err) => {
    // let loginInfo = JSON.parse(localStorage.getItem('auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/auth/invite',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            // 'x-access-token': loginInfo.token
        },
        data : addData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editAdminAction = (data, callback, err) => {
    // let loginInfo = JSON.parse(localStorage.getItem('auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/admin/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            // 'x-access-token': loginInfo.token
        },
        data : data,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getAdminsAction = (search, callback) => {
    api.get('super/admin/list', search)
    .then(function (res) {
        callback(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const sendInviteCode = (data, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'auth/sendmail',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : data,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const updateInviteCode = (inviteData, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/updateInvite',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : inviteData,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const removeAdminsAction = (ids, callback, err) => {
    // let loginInfo = JSON.parse(localStorage.getItem('auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/admin/delete',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            // 'x-access-token': loginInfo.token
        },
        data : {
            ids: ids
        },
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const changeStateAdminAction = (ids, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/admin/change_state',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            ids: ids
        }
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const addClientAction = async (addData, callback, err) => {
    try {
        const resp  = await api.post('super/auth/company/invite', addData);
        resp.status === 200 ? callback(resp.data) : err();
    } catch (error) {
        console.log(error);
        err();
    }
}

export const getClientsAction = (search, callback) => {
    api.post('super/client/list', search)
    .then(function (res) {
        callback(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const removeClientsAction = (ids, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/client/delete',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            ids: ids
        },
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const changeStateClientAction = (ids, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/client/change_state',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            ids: ids
        },
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const changeStateSubClientAction = (data, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('auth'));
    console.log(loginInfo);
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/subclient/changestate',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.token
        },
        data : data,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editSubClientAction = (editId, updateData, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/subclient/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.token
        },
        data : {
            updateData: updateData,
            editId: editId
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}