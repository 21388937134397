import React, {useEffect, useState, useRef } from "react";
import { Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button , Col, Row, Input, Form, Divider, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {getCategories} from '../actions/Questions';
import {
  getSubCategoryAction,
  getCategoryAction
} from '../actions/Game';
import { RiDeleteBin6Line, RiDeleteColumn } from "react-icons/ri";
import { MdRemoveCircle } from "react-icons/md";

const { Text, Link } = Typography;
const { Option } = Select;
let index = 0;

const MIN_ALTERNATIVES = 2;
const COUNT_INIT_ALTERNATIVE = 4;
const ANSWER_DEFAULT = { isCorrect: false, value: "" };

const ManualComponent = (props) => {
  const navigate = useNavigate();
  const setQuestionData = props.setQuestionData;
  const questionData = props.questionData;
  const [question, setQuestionTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [duration, setTime] = useState(20);

  const [answers, setAnswers] = useState([]);
  const [firstAnswer, setFirstAnswer] = useState('');
  const [secondAnswer, setSecondAnswer] = useState('');
  const [thirdAnswer, setThirdAnswer] = useState('');
  const [fourthAnswer, setFourthAnswer] = useState('');
  const [mountState, setMountState] = useState(0);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [initialCategory, setInitialCategory] = useState('');
  const [initialSubCategory, setInitialSubCategory] = useState('');

  const formLayout = 'vertical';
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const initialAnswer = () => {
    const anwersDefault = Array.from(
      { length: COUNT_INIT_ALTERNATIVE }, 
      (_, index) => index + 1).map((value, index) => ({...ANSWER_DEFAULT,  isCorrect: index === 0}));
    setAnswers([...anwersDefault]);
  }

  useEffect(() => {
    if (mountState === 0) {
      setMountState(1);
      // getCategories((data) => {
      //   setCategories(data.categories);
      // });
      getCategoryAction((list) => {
        let categoryList = []
        let firstCategory = ''
        list.map((l, index) => {
          categoryList.push(l.category);
          if (!firstCategory) firstCategory = l.category
        });
        setCategories(categoryList);
        setCategory(firstCategory);
        setInitialCategory(firstCategory);
      });
      getSubCategoryAction((list) => {
        let subCategoryList = []
        let firstSubCategory = ''
        list.map((l, index) => {
          subCategoryList.push(l.subCategory);
          if (!firstSubCategory) firstSubCategory = l.subCategory
        });
        setSubCategories(subCategoryList);
        setInitialSubCategory(firstSubCategory);
      });
    }
  });

  useEffect(() => {
    initialAnswer();
  }, []);

  const createQuestion = () => {
    setQuestionData([
      ...questionData,
      {
      question: question,
      type: 1,
      category: category,
      duration: duration,
      answers: answers
    }]);
    setQuestionTitle('');
    setCategory(initialCategory);
    initialAnswer();
    setTime(20);
  }

  // const [items, setItems] = useState(['jack', 'lucy']);
  const [addCategory, setAddCategory] = useState('');
  const [subAddCategory, setAddSubCategory] = useState('');
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setAddCategory(event.target.value);
  };
  const onSubChange = (event) => {
    setAddSubCategory(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setCategories([...categories, category || `New item ${index++}`]);
    setCategory('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  // answers
  const updateAnswer = (i, value, isCorrect) => {
    const list = [...answers];
    list[i].value = value;
    list[i].isCorrect = isCorrect;
    setAnswers(list);
  }

  const addAnswer = () => {
    const list = [...answers];
    const item = {...ANSWER_DEFAULT, isCorrect: list.length === 0};
    setAnswers([...list, item]);
  }

  const deleteAnswer = (i) => {
    const list = [...answers];
    list.splice(i, 1);
    setAnswers(list);
  }

  const isValidAnswers = () => answers.length >= MIN_ALTERNATIVES && answers.every((item) => item.value !== '');

  return (
    <>
    <Form
      {...formItemLayout}
      layout={formLayout}
    >
      <Row className="mt-medium" gutter={16}>
        <Col
          className="padding-medium"
          span={14} 
        >
            <Form.Item label="Escribe tu pregunta">
              <Input.TextArea rows={4}  placeholder="Ingrese nombre del juego" onChange={((e) => setQuestionTitle(e.target.value))} value={question}/>
            </Form.Item>
            <Form.Item label="Escribe tus respuestas">
              {answers.map((answer, index) => (
                <Input 
                  placeholder={answer.isCorrect ? 'Alternativa correcta' : 'Alternativa incorrecta'}
                  className='hm' 
                  prefix={answer.isCorrect ?  <CheckCircleFilled style={{color: "green",fontSize: '20px'}}/> : <CloseCircleFilled style={{color: "red",fontSize: '20px'}}/> } 
                  onChange={((e) => updateAnswer(index, e.target.value, index === 0))} 
                  value={answers[index] ? answers[index].value : ''}
                  addonAfter={<MdRemoveCircle cursor={'pointer'} style={{color: "#F26E6E",fontSize: '20px'}} onClick={() => deleteAnswer(index)}/>}
                />
              ))}
              <div className="">
                <Button 
                  className={`small-button hm mt-medium`}
                  type="primary" onClick={addAnswer}
                >
                  + Agregar Respuesta
                </Button>
              </div>
            </Form.Item>
            <Text className="small-title mt-medium">* El orden de las respuestas se mezclarán al azar.</Text>
        </Col>
        <Col
          className="padding-medium flex-column v-between"
          span={10} 
        >
          <div>
            <Form.Item label="Ingrese categoría">
                <Input
                  defaultValue="select"
                  style={{
                    width: "100%",
                  }}
                  onChange={((e) => setCategory(e.target.value))} 
                  value={category}
                />
            </Form.Item>
            {/*
            <Form.Item label="Ingrese categoría">
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                  }}
                  onChange={((val) => setCategory(val))} 
                  value={category}
                >
                  {
                    categories.map((c) => {
                      return <Option value={c}>{c}</Option>
                    })
                  }
                </Select>
            </Form.Item>
            {/*<Form.Item label="Añade Subcategoría">
                <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                  }}
                  onChange={((val) => setSubCategory(val))} 
                  value={subCategory}
                >
                  {
                    subCategories.map((s) => {
                      return <Option value={s}>{s}</Option>
                    })
                  }
                </Select>
            </Form.Item>*/}
            <Form.Item label="Tiempo de respuesta">
              <Select
                  defaultValue="select"
                  style={{
                    width: "100%",
                  }}
                  onChange={((val) => setTime(val))} 
                  value={duration}
                >
                  {/* {
                    categories.map((item) => {
                      return <Option value={item}>{item}</Option>
                    })
                  } */}
                  <Option value={10}>10</Option>
                  <Option value={20}>20</Option>
                  <Option value={30}>30</Option>
                  <Option value={40}>40</Option>
                  <Option value={50}>50</Option>
                </Select>
                {/* <Input placeholder="10 segundos" className='hm' onChange={((e) => setTime(e.target.value))} value={duration}/> */}
            </Form.Item>
          </div>
          <div className="w-100 v-end">
            <Button 
              className={`small-button hm mt-medium ${isValidAnswers() ? "" : "back-grey"}`} 
              disabled={!isValidAnswers()}
              type="primary" onClick={() => createQuestion()}
            >
              Agregar pregunta
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
    </>
  );
};

export default ManualComponent;
