import { Button, Modal, Input, Typography, Collapse, Table, List, Col, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCheck, FaUserLock } from "react-icons/fa";
import { ConsoleSqlOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import { BsFillShareFill } from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import InfoModal from "../../components/player/InfoModal";
import CodeModal from "../../components/player/CodeModal";

import { addClientAction, getClientsAction, removeClientsAction, changeStateClientAction, sendInviteCode, changeStateSubClientAction, editSubClientAction } from '../../components/actions/Player';
import { formatAMPM } from '../../function';

import '../../scss/dashboard.scss';

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;


const StyledReactInputVerificationCode = styled.div`
       display: flex;
       justify-content: center;

       --ReactInputVerificationCode-itemWidth: 40px;
       --ReactInputVerificationCode-itemHeight: 40px;
       --ReactInputVerificationCode-itemSpacing: 8px;

       .ReactInputVerificationCode__item {
           font-size: 16px;
           font-weight: 500;
           color: #ABB4C5;
           border: 1px solid
           ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
           border-radius: 4px;
           line-height: 40px;
           box-shadow: none;
           background: #fff;
       }

       .ReactInputVerificationCode__item.is-active {
           box-shadow: none;
           border: 1px solid #36c6d9;
       }
       `;

export default function PlayerPage() {
  const [modalTab, setModalTab] = useState(1);
  const navigate = useNavigate();
  const [verifyCode, setVerifiCode] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [rut, setRut] = useState('');
  const [listState, setListState] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [modalMode, setModalMode] = useState('client');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userDepartment, setUserDepartment] = useState('');
  const [userEditId, setUserEditId] = useState('');
  const [userAddLoading, setUserAddLoading] = useState(0);

  useEffect(() => {
    if (!listState) {
      setListState(1);
      getClientsAction({}, list => {
        let data = list.map((item) => {
          return {
            key: item._id,
            client: item.name,
            firstName: item.name,
            lastName: item.lastName,
            rut: item.rut,
            admin: "Administradores",
            code: item.admin.inviteCode,
            email: item.email,
            state: item.isActive,
            sub: item.sub
          }
        });
        setClientList(data);
        setListState(1);
      });
      var event = new Date();
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      setDate(event.toLocaleDateString('es-ES', options));
      setTime(formatAMPM(event));
      
      const loginInfo = JSON.parse(localStorage.getItem('auth'));
      setUserName(loginInfo.firstName);
    }
  });

  const onSearch = (value) => {
    getClientsAction({
      keyword: value
    }, (list) => {
      let data = list.map((item) => {
        return {
          key: item.id,
          client: item.firstName,
          rut: item.rut,
          admin: "client",
          code: item.inviteCode,
          email: item.email,
          state: item.state,
          users: item.user
        }
      });
      setClientList(data);
    });
  };

  const removeClient = () => {
    removeClientsAction(selectedIds, () => {
      getClientsAction({},
        list => {
          let data = list.map((item) => {
            return {
              key: item.id,
              client: item.firstName,
              rut: item.rut,
              admin: "client",
              code: item.inviteCode,
              email: item.email,
              state: item.state,
              users: item.user
            }
          });
          setClientList(data);
      });
    });
  }

  const changeStateClient = () => {
    changeStateClientAction(selectedIds, () => {
      getClientsAction({},
        list => {
          let data = list.map((item) => {
            return {
              key: item.id,
              client: item.firstName,
              rut: item.rut,
              admin: "client",
              code: item.inviteCode,
              email: item.email,
              state: item.state,
              users: item.user
            }
          });
          setClientList(data);
      });
    });
  }

  const action = () => {
    if (userEditId < 0) {
      addClient();
    } else {
      editSubClient();
    }
  }

  const editSubClient = () => {
    editSubClientAction(userEditId, {
      firstName: userFirstName,
      lastName: userLastName,
      email: userEmail,
      profileType: userDepartment
    }, () => {
      setListState(0);
      setModalTab(1);
      handleCancel();
    });
  }

  const addClient = () => {
    setUserAddLoading(1);
    addClientAction({
      name,
      address: address,
      email: email,
      rut: rut,
    }, async (data) => {
      setVerifiCode(data.inviteCode);
      await getClientsAction({} ,list => {
        let data = list.map((item) => {
          return {
            key: item.id,
            client: item.firstName,
            rut: item.rut,
            admin: "client",
            code: item.inviteCode,
            email: item.email,
            state: item.isActive,
            users: item.user
          }
        });
        setUserAddLoading(0);
        setClientList(data);
        setListState(1);
        setModalTab(2); 
      });
    }, () => {
      setUserAddLoading(0);
      setModalTab(1);
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let selected = selectedRows.map(row => {
        return row.key;
      });
      setSelectedIds(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const changeStateSubClient = (id) => {
    changeStateSubClientAction({
      id: id,
    }, () => {
      setListState(0);
    });
  }

  const formLayout = 'vertical';
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const clientTableColumn = [
    {
      title: 'Clientes',
      dataIndex: 'client',
      key: 'client',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Administradores',
      dataIndex: 'admin',
      key: 'admin',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Codigo',
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (state) => (
        state ? "active" : "Desactivado"
      )
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <BsFillShareFill style={{color: "#8146ff", fontSize: "20px"}} onClick={() => {showModalInvite({toAddress: record.email, text: record.code})}}/>
      )
    },
    Table.EXPAND_COLUMN,
  ];

  const [open, setOpen] = useState(false);
  const showModal = () => {
      setModalMode('client');
      setUserEditId(-1);
      setModalTab(1);
      setOpen(true);
  };
  const handleOk = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const handleCancel = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteCodeModal, setInviteCodeModal] = useState('');
  const [inviteData, setInviteData] = useState('');
  const showModalInvite = (data) => {
    setInviteCodeModal(data.text);
    setInviteData(data);
    setOpenInvite(true);
  };
  const handleOkInvite = (e) => {
    setOpenInvite(false);
  };
  
  const handleCancelInvite = (e) => {
    setOpenInvite(false);
  };

  const sendInvite = (inviteData) => {
    sendInviteCode(inviteData, () => {
      handleCancelInvite();
    });
  }
  
  const showEditModal = (data) => {
    setModalTab(1);
    setModalMode('user');
    setUserFirstName(data.firstName);
    setUserLastName(data.lastName);
    setUserEmail(data.email);
    setUserDepartment(data.profileType);
    setUserEditId(data.id);
    setOpen(true);
  };

  const createComapny = () => {

    action();

  }

  return (
    <div>
      <div className='content-h' style={{}}>
          <div className='v-between  w-100 h-end'>
              <Title className='large-title no-margin'>Hola {username},</Title>
              <Text className='smail-title'>{date}</Text>
          </div>
          <div className='v-between  w-100'>
              <Text className='bit-title'>En esta sección podras agregar jugadores, ya sea de forma manual o con ayuda de nuestra plantilla disponible.</Text>
              <Text className='bit-title'>{time}</Text>
          </div>
      </div>
      <div className='dashboard'>
        <div className='panel' style={{background: "#F5F5F5"}}>
            <div className='v-between'>
                <Title className='large-title no-margin bold'>Clientes asociados</Title>
                <div className='v-end h-center'>
                  <Search
                      placeholder="Buscar usuario"
                      allowClear
                      onSearch={onSearch}
                      style={{
                          width: "30%",
                      }}
                  />
                  <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => {changeStateClient()}}>
                    Pausar
                  </Button>
                  <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => {removeClient()}}>
                    Eliminar
                  </Button>
                </div>
            </div>
            <div className='mt-large padding-small borderS' style={{background: "#fafafa"}}>
              <Table
                columns={clientTableColumn}
                dataSource={clientList}
                pagination={false}
                expandIcon={({ expanded, onExpand, record }) =>
                  expanded ? (
                    <UpOutlined style={{float: 'right'}} onClick={e => onExpand(record, e)} />
                  ) : (
                    <DownOutlined onClick={e => onExpand(record, e)} />
                  )
                }
                expandable={{
                  expandedRowRender: (record) => (
                    <List
                      size="large"
                      dataSource={record.sub}
                      renderItem={item => (
                        <List.Item>
                          <Col span={4}></Col>
                          <Col span={6}>{item.firstName}</Col>
                          <Col span={6}>Cliente</Col>
                          <Col span={5}>{item.state === true ? "Activo" : "Inactivo"}</Col>
                          <Col span={3}>
                          <div className='v-around h-center'>
                            <FiEdit style={{color: "#8146ff", fontSize: "20px"}} onClick={() => showEditModal(item)}/>
                            <BsFillShareFill onClick={() => showModalInvite({toAddress: item.email, text: item.inviteCode})} style={{color: "#8146ff", fontSize: "20px"}}/>
                            {
                              item.state === true ?
                                <FaPause onClick={() => changeStateSubClient(item.id)} style={{color: "#f26e6e", fontSize: "20px"}}/>
                                :
                                <FaPlay onClick={() => changeStateSubClient(item.id)} style={{color: "#56c456", fontSize: "18px"}}/>
                            }
                          </div>
                          </Col>
                        </List.Item>
                      )}
                    />
                    // <p>{JSON.stringify(record.users)}</p>
                  ),
                }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            </div>
            <div className='v-center mt-large'>
                <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => showModal()}>
                  Agregar Cliente
                </Button>
            </div>
          </div>
      </div>
      <Modal
            title={modalMode === 'client' ? 'Añadir nuevo cliente' : "Editar administrador"}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width="70%"
            // height="60%"
            style={{
              paddingBottom: "0px",
            }}
            footer=''
            className='business-modal'
        >
            <div className='modal-content flex-column v-between h-center' style={{border: "none", height: "100%"}}>
                <div className='w-100'>
                  {modalTab === 1 ? 
                    modalMode === 'client' ?
                      <InfoModal setName={setName} setAddress={setAddress} setEmail={setEmail} setRut={setRut} modalMode={modalMode}/> 
                      :
                      <InfoModal 
                        setUserFirstName={setUserFirstName} 
                        setUserLastName={setUserLastName} 
                        setUserEmail={setUserEmail} 
                        setUserDepartment={setUserDepartment}
                        userFirstName={userFirstName}
                        userLastName={userLastName}
                        userEmail={userEmail}
                        userDepartment={userDepartment}
                        modalMode={modalMode}
                      /> 
                    : 
                    <CodeModal verifiedCode={verifyCode}/>}
                </div>
                {modalTab === 1 ? 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' onClick={() => handleCancel()} type="primary">Volver</Button>
                    <Button 
                      className='bit-button hs ml-medium' 
                      onClick={createComapny} 
                      type="primary"
                      disabled={!name || !address || !email || !rut ? true : false}
                    >
                      Agregar
                    </Button>
                  </div>
                  : 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' 
                      onClick={() => { handleCancel()}} type="primary"
                      disabled={!userAddLoading?false:true}
                    >
                      {
                        !userAddLoading ? 
                          "Finalizar"
                          :
                          <Spin size="small"/>
                      }
                    </Button>
                  </div>
                }
            </div>
        </Modal>
        
        <Modal
            title=''
            open={openInvite}
            onOk={handleOkInvite}
            onCancel={handleCancelInvite}
            footer=''
        >
            <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "70%"}}>
                <Text className="large-title color-b mt-medium">Compartir código por email</Text>    
                <StyledReactInputVerificationCode className="mt-bit mt-bit mt-medium">
                    <ReactInputVerificationCode length={5} placeholder={0} type='string' value={inviteCodeModal}/>
                </StyledReactInputVerificationCode>
                <Text className="medium-title mt-bit text-center mt-medium">Enviaremos el codigo de acceso al correo añadido del usuario</Text>
                <div className='mt-medium' style={{display: "flex"}}>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancelInvite()}>Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => sendInvite(inviteData)} type="primary">Enviar Correo</Button>
                </div>
              </div>
            </div>
        </Modal>
    </div>
  );
}