import api from '../../api/interceptor';

export const getStatisAction = (date, callback, err) => {
    api.post('super/statistic', {date})
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}