import { Button, Form, Input, Modal, Typography, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import ReactCodeInput from "react-code-input";
import styled from "styled-components";

import { registerAction } from '../../components/actions/Sign';
const { Text, Title } = Typography;
const codeInputStyle = {
  inputStyle: {
    fontFamily: 'Inter',
    margin:  '4px',
    MozAppearance: 'textfield',
    width: '50px',
    borderRadius: '7px',
    fontSize: '1vw',
    height: '6vh',
    backgroundColor: '#fff',
    color: '#727D91',
    border: '1px solid #EAEAEA',
    textAlign: "center",
  }
}

export default function RegisterPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(0);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [verifiedCode, setVerifiedCode] = useState('00000');

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const formLayout = 'vertical';

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === 'horizontal'
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;

   const StyledReactInputVerificationCode = styled.div`
        display: flex;
        justify-content: center;

        --ReactInputVerificationCode-itemWidth: 6vh;
        --ReactInputVerificationCode-itemHeight: 6vh;
        --ReactInputVerificationCode-itemSpacing: 8px;

        .ReactInputVerificationCode__item {
            font-size: 1vw;
            font-weight: 500;
            color: #ABB4C5;
            border: 1px solid
            ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
            border-radius: 4px;
            line-height: 6vh;
            box-shadow: none;
            background: #fff;
        }

        .ReactInputVerificationCode__item.is-active {
            box-shadow: none;
            border: 1px solid #36c6d9;
        }
        `;

    const register = () => {
      setLoading(1);
      registerAction({
        email: email,
        password: password,
        inviteCode: verifiedCode
      }, () => {
        setLoading(0);
        showModal();
      }, () => {
        setLoading(0);
      });
    }

    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = (e) => {
        console.log(e);
        setOpen(false);
    };
    
    const handleCancel = (e) => {
        console.log(e);
        setOpen(false);
    };
  return (
    <>
        <Form
          className='login-content flex-column v-between'
          {...formItemLayout}
          layout={formLayout}
          form={form}
          initialValues={{ layout: formLayout }}
          style={{minHeight: "85%", maxHeight: "100%", overflow: "y"}}
        >
          <Title className='login-header-title'>Crear contraseña nueva</Title>
          <Text className='login-common-title'>Por favor ingrese sus datos junto con el código enviado a su email para crear una nueva contraseña.</Text>
          <Form.Item label="Correo" className='mt-small login-input'>
              <Input placeholder="Ingrese su correo" style={{height: "6vh", borderRadius: "7px"}} onChange={ e => setEmail(e.target.value) }/>
          </Form.Item>
          <Form.Item label="Código de acceso" className='login-input'>
              <div className='v-center'>
                <ReactCodeInput
                    name="resetPassword"
                    inputMode="numeric"
                    fields={5}
                    type="text"
                    onChange={(e) => setVerifiedCode(e)}
                    isValid={verifiedCode}
                    {...codeInputStyle}
                  />
              </div>
          </Form.Item>
          <Form.Item label="Nueva contraseña" className='login-input'>
              <Input.Password placeholder="Ingrese una nueva contraseña" style={{height: "6vh", borderRadius: "7px"}} onChange={ e => setPassword(e.target.value) }/>
          </Form.Item>
          <Form.Item label="Repita la contraseña" className='login-input'>
              <Input.Password placeholder="Ingrese una nueva contraseña nuevamente" style={{height: "6vh", borderRadius: "7px"}} onChange={ e => setPasswordConfirm(e.target.value) }/>
          </Form.Item>
          <Form.Item {...buttonItemLayout} >
              <Button className='long-button primary'
                style={{height: "6vh", fontSize: "1.2vw"}}
                onClick={() => register()} 
                disabled = { email === '' || password === '' || password !== passwordConfirm || verifiedCode === '00000' ? true : false } 
                type="primary" 
              >
                {
                  !loading ? 
                    "Registrarse"
                    :
                    <Spin size='small'/>
                }
              </Button>
          </Form.Item>
        </Form>
        <Modal
            title=''
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer=''
        >
            <div className='modal-content' style={{border: "none"}}>
                <img src='/img/success.png' width="91"/>
                <h3>¡Su cuenta ha sido creada exitosamente!</h3>
                <p>Ahora se activará tu cuenta</p>
                <Button className='modal-button hm' onClick={() => navigate('/login')} type="primary">Aceptar</Button>
            </div>
        </Modal>
  </>
  );
}
