import { Button, Modal, Input, Typography, Collapse, Table, Form, Col, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import { DownOutlined } from '@ant-design/icons';
import { FiEdit } from "react-icons/fi";
import { BsFillShareFill } from "react-icons/bs";

import InfoModal from "../../components/player/InfoModal";
import CodeModal from "../../components/player/CodeModal";

import { addAdminAction, getAdminsAction, removeAdminsAction, changeStateAdminAction, sendInviteCode, editAdminAction } from '../../components/actions/Player';
import { formatAMPM } from '../../function';

import '../../scss/dashboard.scss';

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;

const StyledReactInputVerificationCode = styled.div`
       display: flex;
       justify-content: center;

       --ReactInputVerificationCode-itemWidth: 40px;
       --ReactInputVerificationCode-itemHeight: 40px;
       --ReactInputVerificationCode-itemSpacing: 8px;

       .ReactInputVerificationCode__item {
           font-size: 16px;
           font-weight: 500;
           color: #ABB4C5;
           border: 1px solid
           ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
           border-radius: 4px;
           line-height: 40px;
           box-shadow: none;
           background: #fff;
       }

       .ReactInputVerificationCode__item.is-active {
           box-shadow: none;
           border: 1px solid #36c6d9;
       }
       `;

export default function AdministratorPage() {
  const navigate = useNavigate();
  const [modalTab, setModalTab] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [rut, setRut] = useState('');
  const [verifyCode, setVerifiCode] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [adminList, setAdminList] = useState([]);
  const [listState, setListState] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [editId, setEditId] = useState(0);
  const [adminAddLoading, setAdminAddLoading] = useState(0);
  const [invitingLoading, setInvitingLoading] = useState(0);

  const init = () => {
    setFirstName('');
    setLastname('');
    setEmail('');
    setRut('');
    setVerifiCode('');
  }

  useEffect(() => {
    if (!listState) {
      getAdminsAction({}, list => {
        let data = list.map((item) => {
          return {
            key: item._id,
            name: item.firstName + " " + item.lastName,
            firstName: item.firstName,
            lastName: item.lastName,
            rut: item.rut,
            email: item.email,
            code: item.inviteCode,
            state: item.isActive
          }
        });
        setAdminList(data);
        setListState(1);
      });
      var event = new Date();
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      setDate(event.toLocaleDateString('es-ES', options));
      setTime(formatAMPM(event));
      setListState(1);
      const loginInfo = JSON.parse(localStorage.getItem('auth'));
      setUserName(loginInfo.firstName);
    }
  });

  const action = () => {
    if (editId === 0) addAdmin();
    if (editId !== 0) editAdmin();
  }

  const onSearch = (value) => {
    getAdminsAction({
      keyword: value
    }, (list) => {
      let data = list.map((item) => {
        return {
          key: item._id,
          name: item.firstName + " " + item.lastName,
          firstName: item.firstName,
          lastName: item.lastName,
          rut: item.rut,
          email: item.email,
          code: item.inviteCode,
          state: item.isActive
        }
      });
      setAdminList(data);
    });
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let selected = selectedRows.map(row => {
        return row.key;
      });
      setSelectedIds(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const removeAdmin = () => {
    removeAdminsAction(selectedIds, () => {
      getAdminsAction({},
        list => {
          let data = list.map((item) => {
            return {
              key: item._id,
              name: item.firstName + " " + item.lastName,
              firstName: item.firstName,
              lastName: item.lastName,
              rut: item.rut,
              email: item.email,
              code: item.inviteCode,
              state: item.isActive
            }
          });
          setAdminList(data);
      });
    });
  }

  const addAdmin = () => {
    setAdminAddLoading(1);
    addAdminAction({
      firstName: firstName,
      lastName: lastName,
      email: email,
      rut: rut,
      inviteCode: verifyCode,
    }, (data) => {
      sendInvite({toAddress: email, text: verifyCode});
      getAdminsAction({} ,list => {
        let data = list.map((item) => {
          return {
            key: item._id,
            name: item.firstName + " " + item.lastName,
            firstName: item.firstName,
            lastName: item.lastName,
            rut: item.rut,
            email: item.email,
            code: item.inviteCode,
            state: item.isActive
          }
        });
        setAdminList(data);
        setListState(1);
        setModalTab(1); 
        handleCancel();
        setAdminAddLoading(0);
      });
    }, () => {
      setAdminAddLoading(0);
      setModalTab(1); 
    });
  }

  const editAdmin = () => {
    setAdminAddLoading(1);
    editAdminAction({
      id: editId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      rut: rut,
      inviteCode: verifyCode,
    }, (data) => {
      getAdminsAction({} ,list => {
        let data = list.map((item) => {
          return {
            key: item._id,
            name: item.firstName + " " + item.lastName,
            firstName: item.firstName,
            lastName: item.lastName,
            rut: item.rut,
            email: item.email,
            code: item.inviteCode,
            state: item.isActive
          }
        });
        setAdminList(data);
        setListState(1);
        setModalTab(1); 
        handleCancel();
        setAdminAddLoading(0);
      });
    });
  }

  const formLayout = 'vertical';
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const superListColumn = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'client',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Codigo',
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (state) => (
        state ? "Registrado" : "Pendiente"
      )
    },
    Table.EXPAND_COLUMN,
    {
      title: '',
      dataIndex: 'info',
      key: 'info',
      render: (d, record) => (
        <div className='v-around h-center'>
          <FiEdit style={{color: "#8146ff", fontSize: "20px"}} onClick={() => {showEditModal({id: record.key, firstName: record.firstName, lastName: record.lastName, rut: record.rut, email: record.email, verifyCode: record.code})}}/>
          <div style={{width: "5px"}}></div>
          {!record.state ? (
            <BsFillShareFill style={{color: "#8146ff", fontSize: "20px", padding: "1px"}} onClick={() => {showModalInvite({toAddress: record.email, text: record.code})}}/>
          ) : <div style={{width: "20px"}}></div>}
        </div>
      )
    },
  ];

  const changeState = () => {
    changeStateAdminAction(selectedIds, () => {
      getAdminsAction({} ,list => {
        let data = list.map((item) => {
          return {
            key: item._id,
            name: item.firstName + " " + item.lastName,
            firstName: item.firstName,
            lastName: item.lastName,
            rut: item.rut,
            email: item.email,
            code: item.inviteCode,
            state: item.isActive
          }
        });
        setAdminList(data);
        handleCancel1();
      });
    });
  }

  const [open, setOpen] = useState(false);
  const showAddModal = () => {
      init();
      setEditId(0);
      setOpen(true);
  };
  const showEditModal = (data) => {
      setEditId(data.id);
      setFirstName(data.firstName);
      setLastname(data.lastName);
      setEmail(data.email);
      setRut(data.rut);
      setVerifiCode(data.verifyCode);
      setOpen(true);
  };
  const handleOk = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const handleCancel = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const [open1, setOpen1] = useState(false);
  const showModal1 = () => {
      setOpen1(true);
  };
  const handleOk1 = (e) => {
      console.log(e);
      setOpen1(false);
  };
  
  const handleCancel1 = (e) => {
      console.log(e);
      setOpen1(false);
  };
  
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteCodeModal, setInviteCodeModal] = useState('');
  const [inviteData, setInviteData] = useState('');
  const showModalInvite = (data) => {
    setInviteCodeModal(data.text);
    setInviteData(data);
    setOpenInvite(true);
    console.log(data);
  };
  const handleOkInvite = (e) => {
    setOpenInvite(false);
  };
  
  const handleCancelInvite = (e) => {
    setOpenInvite(false);
  };

  const sendInvite = (inviteData) => {
    setInvitingLoading(1);
    sendInviteCode(inviteData, () => {
      handleCancelInvite();
      setInvitingLoading(0);
    });
  }
  
  return (
    <div>
      <div className='content-h' style={{}}>
          <div className='v-between  w-100 h-end'>
              <Title className='large-title no-margin'>Hola {username},</Title>
              <Text className='smail-title'>{date}</Text>
          </div>
          <div className='v-between  w-100'>
              <Text className='bit-title'>Aquí podrás administrar a los super administradores de Beewise</Text>
              <Text className='bit-title'>{time}</Text>
          </div>
      </div>
      <div className='dashboard'>
        <div className='panel' style={{background: "#F5F5F5"}}>
            <div className='v-between'>
                <Title className='large-title no-margin bold'>Super Administradores</Title>
                <div className='v-end h-center'>
                  <Search
                      placeholder="Buscar cliente o Administrador"
                      allowClear
                      onSearch={onSearch}
                      style={{
                          width: "30%",
                      }}
                  />
                  <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => showModal1()}>
                    Pausar
                  </Button>
                  <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => removeAdmin()}>
                    Eliminar
                  </Button>
                </div>
            </div>
            <div className='mt-large padding-small borderS' style={{background: "#fafafa"}}>
              <Table
                columns={superListColumn}
                dataSource={adminList}
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            </div>
            <div className='v-center mt-large'>
                <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => showAddModal()}>
                  Agregar Cliente
                </Button>
            </div>
          </div>
      </div>
      <Modal
            title='Añadir nuevo super administrador'
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width="70%"
            // height="60%"
            style={{
              paddingBottom: "0px",
            }}
            footer=''
            className='business-modal'
        >
            <div className='modal-content flex-column v-between h-center' style={{border: "none", height: "100%"}}>
                <div className='w-100'>
                  {modalTab === 1 ? 
                    <InfoModal type="admin" 
                      fname={firstName}
                      lname={lastName}
                      email={email}
                      rut={rut}
                      firstName={setFirstName} 
                      lastName={setLastname} 
                      setEmail={setEmail} 
                      setRut={setRut}
                    /> 
                    : 
                    <CodeModal type="admin" setVerify={setVerifiCode}/>}
                </div>
                {modalTab === 1 ? 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' onClick={() => handleCancel()} type="primary">Volver</Button>
                    <Button className='bit-button hs ml-medium' onClick={() => setModalTab(2)} type="primary">Agregar</Button>
                  </div>
                  : 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' onClick={() => {action()}} type="primary"
                      disabled={!adminAddLoading?false:true}
                    >
                      {
                        !adminAddLoading ? 
                          "Agregar Super Administrador"
                          :
                          <Spin size="small"/>
                      }
                    </Button>
                  </div>
                }
            </div>
        </Modal>
        
        <Modal
            title=''
            open={open1}
            onOk={handleOk1}
            onCancel={handleCancel1}
            footer=''
        >
            <div className='modal-content' style={{border: "none"}}>
                <img src='/img/icon/warning.png' width="91"/>
                <h3>Pausar Administrador</h3>
                <Text className='medium-title text-center'>¿Estas seguro de que quieres  pausar la actividad de Pedro Ulgade?</Text>
                <p>Esto significa que CPedro Ugalde no podra ingresar nuevamente al sitio web impidiendo sus actividades</p>
                <div>
                  <Button className='bit-button hs' type="primary">Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => changeState()} type="primary">Pausar</Button>
                </div>
            </div>
        </Modal>
        
        <Modal
            title=''
            open={openInvite}
            onOk={handleOkInvite}
            onCancel={handleCancelInvite}
            footer=''
        >
            <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "70%"}}>
                <Text className="large-title color-b mt-medium">Compartir código por email</Text>    
                <StyledReactInputVerificationCode className="mt-bit mt-bit mt-medium">
                    <ReactInputVerificationCode length={5} placeholder={0} type='string' value={inviteCodeModal}/>
                </StyledReactInputVerificationCode>
                <Text className="medium-title mt-bit text-center mt-medium">
                  Enviaremos el codigo de acceso al correo añadido del usuario
                </Text>
                <div className='mt-medium' style={{display: "flex"}}>
                  <Button className='bit-button hs' type="primary" onClick={() => {}}>Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => sendInvite(inviteData)} type="primary"
                    disabled={!invitingLoading?false:true}
                  >
                    {
                      !invitingLoading ? 
                        "Enviar Correo"
                        :
                        <Spin size="small"/>
                    }
                  </Button>
                </div>
              </div>
            </div>
        </Modal>
    </div>
  );
}
