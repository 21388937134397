import axios from 'axios';
import {SERVER_URL} from '../../constant';
import api from '../../api/interceptor';


export const getGameLibraryList = (callback, err) => {
    api.get('super/game/list')
    .then(function (res) {
        console.log('res.data.list', res.data.list);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getQuizData = (id ,callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'game/quiz/list',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            topicId: id
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getGameLibrary = (id ,callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/statis',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            id: id
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getSharedGames = (callback, err) => {
    var config = {
        method: 'get',
        url: SERVER_URL + 'super/game/sharedlist',
        crossdomain: true,
        // headers: { 
            // Authorization: `Bearer ${loginInfo.token}`
        // },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.result) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getSharedCompetitions = (callback, err) => {
    var config = {
        method: 'get',
        url: SERVER_URL + 'super/competitions/sharedlist',
        crossdomain: true,
        // headers: { 
            // Authorization: `Bearer ${loginInfo.token}`
        // },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.result) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editQuestionAction = (id, uploadData, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'game/quiz/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            id: id,
            uploadData: uploadData
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const removeQuestionAction = (id, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'game/quiz/remove',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            id: id
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const shareGameAction = (gameIds, clientIds, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'super/game/share',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.token}`
        },
        data : {
            gameIds: gameIds,
            clientIds: clientIds
        },
    };
    
    api.put('super/game/share', {gameIds, clientIds})
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getCategoryAction = (callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('auth'));
    var config = {
        method: 'get',
    url: SERVER_URL + 'super/category/list',
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.token
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getSubCategoryAction = (callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'super/subcategory/list',
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.token
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getGameData = (data, callback, err) => {
    api.post("/admin/game/get", data)
    .then(function (res) {
        res.status === 200 ? callback(res.data.game) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}