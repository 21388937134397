import { Button, Select, Input, Typography, Collapse, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { getStatisAction } from '../../components/actions/Dashboard';
import { formatAMPM } from '../../function';

import '../../scss/dashboard.scss';

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;

export default function DashboardPage() {
  const navigate = useNavigate();
  const [dataState, setDataState] = useState(0);
  const [clientCnt, setClientCnt] = useState(0);
  const [userCnt, setUserCnt] = useState(0);
  const [startedGameCnt, setStartedGameCnt] = useState(0);
  const [startedChallengeCnt, setStartedChallengeCnt] = useState(0);
  const [difClientCnt, setDifClientCnt] = useState(0);
  const [difUserCnt, setDifUserCnt] = useState(0);
  const [difStartedGameCnt, setDifStartedGameCnt] = useState(0);
  const [difStartedChallengeCnt, setDifStartedChallengeCnt] = useState(0);
  const [month, setMonth] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');

  useEffect(() => {
    if (!dataState) {
      setDataState(1);
      const cDate = new Date();
      const date = new Date(cDate.getFullYear(), cDate.getMonth(), "01");
      getStatisAction(date, data => {
        setClientCnt(data.result.clientCnt);
        setUserCnt(data.result.userCnt);
        setStartedGameCnt(data.result.startedGameCnt);
        setStartedChallengeCnt(data.result.startedChallengeCnt);

        setDifClientCnt(data.dif.clientCnt);
        setDifUserCnt(data.dif.userCnt);
        setDifStartedGameCnt(data.dif.startedGameCnt);
        setDifStartedChallengeCnt(data.dif.startedChallengeCnt);
      });
      var event = new Date();
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      setDate(event.toLocaleDateString('es-ES', options));
      setTime(formatAMPM(event));
      const loginInfo = JSON.parse(localStorage.getItem('auth'));
      setUserName(loginInfo.firstName);

      let optionsMonth = { month: 'long' };
      let monthString = event.toLocaleDateString('es-ES', optionsMonth)
      setMonth(monthString.charAt(0).toUpperCase() + monthString.slice(1));
    }
  });

  const selectMonth = (month) => {
    const cDate = new Date();
    const date = new Date(cDate.getFullYear(), parseInt(month)-1, 1);console.log(date);
    getStatisAction(date, data => {
      setClientCnt(data.result.clientCnt);
      setUserCnt(data.result.userCnt);
      setStartedGameCnt(data.result.startedGameCnt);
      setStartedChallengeCnt(data.result.startedChallengeCnt);

      setDifClientCnt(data.dif.clientCnt);
      setDifUserCnt(data.dif.userCnt);
      setDifStartedGameCnt(data.dif.startedGameCnt);
      setDifStartedChallengeCnt(data.dif.startedChallengeCnt);
      
      let optionsMonth = { month: 'long' };
      let monthString = date.toLocaleDateString('es-ES', optionsMonth)
      setMonth(monthString.charAt(0).toUpperCase() + monthString.slice(1));
    });
  }

  return (
    <div>
      <div className='content-h' style={{}}>
          <div className='v-between  w-100 h-end'>
              <Title className='large-title no-margin'>Hola {username},</Title>
              <Text className='smail-title'>{date}</Text>
          </div>
          <div className='v-between  w-100'>
              <Text className='bit-title'>Bienvenido al Dashboard.</Text>
              <Text className='bit-title'>{time}</Text>
          </div>
      </div>
      <div className='dashboard'>
          <div className='panel' style={{background: "#F5F5F5"}}>
              <div className='v-between'>
                  <Title className='large-title no-margin bold'>Mes - {month}</Title>
                  <Select
                    defaultValue="Selecciona un mes"
                    style={{
                      width: 200,
                    }}
                    onChange={(val) => {selectMonth(val)}}
                  >
                    <Option value="1">Enero</Option>
                    <Option value="2">Febrero</Option>
                    <Option value="3">Marzo</Option>
                    <Option value="4">Abril</Option>
                    <Option value="5">Mayo</Option>
                    <Option value="6">Junio</Option>
                    <Option value="7">Julio</Option>
                    <Option value="8">Agosto</Option>
                    <Option value="9">Septiembre</Option>
                    <Option value="10">Octubre</Option>
                    <Option value="11">Noviembre</Option>
                    <Option value="12">Diciembre</Option>
                  </Select>
              </div>
              <div className='mt-bit'>
                <Row gutter={16}>
                  <Col span={8} className='gutter-row' >
                    <div className='padding-small borderS' style={{background: "#FDFDFF"}}>
                      <Text className='small-title color-black'>
                        Número de Clientes:
                      </Text>
                      <div className='v-between'>
                        <Text className='large-title v-end mt-large'>
                          {clientCnt} clientes
                        </Text> 
                        <Text className='large-title v-end mt-large'>
                          {
                            difClientCnt > 0 ? 
                              <><ArrowUpOutlined style={{display: "flex", alignItems: "center", fontSize: "14px"}} />+ {difClientCnt}</>
                              :
                              <><ArrowDownOutlined style={{display: "flex", alignItems: "center", fontSize: "14px", color: "#FD5935"}} />{difClientCnt}</>
                          }
                        </Text> 
                      </div>
                    </div>
                  </Col>
                  <Col span={8} className='gutter-row' >
                    <div className='padding-small borderS' style={{background: "#FFEEAC"}}>
                      <Text className='small-title color-black'>
                        Número de Usuarios:
                      </Text>
                      <div className='v-between'>
                        <Text className='large-title v-end mt-large'>
                          {userCnt} usuarios
                        </Text> 
                        <Text className='large-title v-end mt-large'>
                          {
                            difUserCnt > 0 ? 
                              <><ArrowUpOutlined style={{display: "flex", alignItems: "center", fontSize: "14px"}} />+ {difUserCnt}</>
                              :
                              <><ArrowDownOutlined style={{display: "flex", alignItems: "center", fontSize: "14px", color: "#FD5935"}} />{difUserCnt}</>
                          }
                        </Text> 
                      </div>
                    </div>
                  </Col>
                  <Col span={8} className='gutter-row' >
                    <div className='padding-small borderS' style={{background: "#FDFDFF"}}>
                      <Text className='small-title color-black'>
                        Número de Juegos iniciados:
                      </Text>
                      <div className='v-between'>
                        <Text className='large-title v-end mt-large'>
                          {startedGameCnt} juegos
                        </Text> 
                        <Text className='large-title v-end mt-large'>
                          {
                            difStartedGameCnt > 0 ? 
                              <><ArrowUpOutlined style={{display: "flex", alignItems: "center", fontSize: "14px"}} />+ {difStartedGameCnt}</>
                              :
                              <><ArrowDownOutlined style={{display: "flex", alignItems: "center", fontSize: "14px", color: "#FD5935"}} />{difStartedGameCnt}</>
                          }
                        </Text> 
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='mt-medium'>
                <Row gutter={16}>
                  <Col span={12} className='gutter-row' >
                    <div className='padding-small borderS' style={{background: "#FDFDFF"}}>
                      <Text className='small-title color-black'>
                        Competencias iniciadas:
                      </Text>
                      <div className='v-between'>
                        <Text className='large-title v-end mt-large'>
                          0 competencias
                        </Text> 
                        <Text className='large-title v-end mt-large'>
                          <ArrowDownOutlined style={{display: "flex", alignItems: "center", fontSize: "14px"}} /> 0
                        </Text> 
                      </div>
                    </div>
                  </Col>
                  <Col span={12} className='gutter-row' >
                    <div className='padding-small borderS' style={{background: "#FFEEAC"}}>
                      <Text className='small-title color-black'>
                        Desafíos iniciados:
                      </Text>
                      <div className='v-between'>
                        <Text className='large-title v-end mt-large'>
                          {startedChallengeCnt} competencias
                        </Text> 
                        <Text className='large-title v-end mt-large'>
                          {
                            difStartedChallengeCnt > 0 ? 
                              <><ArrowUpOutlined style={{display: "flex", alignItems: "center", fontSize: "14px"}} />+ {difStartedChallengeCnt}</>
                              :
                              <><ArrowDownOutlined style={{display: "flex", alignItems: "center", fontSize: "14px", color: "#FD5935"}} />{difStartedChallengeCnt}</>
                          }
                        </Text> 
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
          </div>
      </div>
    </div>
  );
}
