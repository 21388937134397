import React, { useEffect, useLayoutEffect } from "react";
import { Typography, Form, Row, Col, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Text, Link } = Typography;

const InfoModal = (props) => {
  const formLayout = 'vertical';
  const setName = props.setName;
  const setFirstName = props.firstName;
  const setLastName = props.lastName;
  const setAddress = props.setAddress;
  const setEmail = props.setEmail;
  const setRut = props.setRut;
  const fname = props.fname;
  const lname = props.lname;
  const email = props.email;
  const rut = props.rut;
  const mode = props.modalMode;

  const setUserFirstName = props.setUserFirstName;
  const setUserLastName = props.setUserLastName;
  const setUserEmail = props.setUserEmail;
  const setUserDepartment = props.setUserDepartment;
  const userFirstName = props.userFirstName;
  const userLastName = props.userLastName;
  const userEmail = props.userEmail;
  const userDepartment = props.userDepartment;

  useEffect(() => {
    console.log(userLastName);
  });

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
    
  return (
    <Form
      {...formItemLayout}
      layout={formLayout}
    >
      <Row>
        <Col className='padding-small' span={12}>
          <Form.Item label={props.type !== 'admin' ? mode === "user" ? "Nombre":"Nombre cliente" : "Nombre "}>
            {
              props.type !== 'admin' ? 
                mode === 'user' ?
                  <Input placeholder="Ingrese nombre" className='hm' onChange={(e) => setUserFirstName(e.target.value)} value={userFirstName}/>
                  :
                  <Input placeholder="Ingrese nombre" className='hm' onChange={(e) => setName(e.target.value)}/>
                :
                <Input placeholder="Ingrese nombre" className='hm' onChange={(e) => setFirstName(e.target.value)} value={fname}/>
            }
          </Form.Item>
        </Col>
        <Col className='padding-small' span={12}>
          <Form.Item label={props.type !== 'admin' ? mode === "user" ? "Apellido":"Dirección" : "Apellido"}>
            {
              props.type !== 'admin' ? 
                mode === 'user' ?
                  <Input placeholder="Ingrese su apellido" className='hm' onChange={(e) => setUserLastName(e.target.value)} value={userLastName}/>
                  :
                  <Input placeholder="Ingrese nombre" className='hm' onChange={(e) => setAddress(e.target.value)}/>
                :
                <Input placeholder="Ingrese su apellido" className='hm' onChange={(e) => setLastName(e.target.value)} value={lname}/>
            }
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className='padding-small' span={12}>
          <Form.Item label={props.type !== 'admin' ? mode === "user" ? "Email":"Rut cliente" : "Rut"}>
            {
              props.type !== 'admin' ? 
                mode === 'user' ?
                  <Input placeholder="Ingrese un email" className='hm' onChange={(e) => setUserEmail(e.target.value)} value={userEmail}/>
                  :
                  <Input placeholder={props.type !== 'admin' ? "Ingrese el RUT del cliente" : "Ingrese el RUT"} onChange={(e) => setRut(e.target.value)} className='hm'/>
                :
                <Input placeholder="Ingrese un email " onChange={(e) => setRut(e.target.value)} value={rut} className='hm'/>
            }
          </Form.Item>
        </Col>
        <Col className='padding-small' span={12}>
          <Form.Item label={mode === "user" ? "Tipo de perfil":"Email"}>
            {
              props.type !== 'admin' ? 
                mode === 'user' ?
                  <Input placeholder="perfil" className='hm' onChange={(e) => setUserDepartment(e.target.value)} value={userDepartment}/>
                  :
                  <Input placeholder="Ingrese un email " onChange={(e) => setEmail(e.target.value)} className='hm'/> 
                :
                <Input placeholder="Ingrese un email " onChange={(e) => setEmail(e.target.value)} value={email} className='hm'/>
            }
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InfoModal;
