import { Button, Steps, Input, Typography, Collapse, Table, Modal, Select, Row, Col, Form } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, ConsoleSqlOutlined } from '@ant-design/icons';
import styled from "styled-components";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Scrollbar from "perfect-scrollbar";

import { 
  getSharedGames, 
  getGameLibraryList, 
  getSharedCompetitions, 
  getQuizData, 
  editQuestionAction,
  removeQuestionAction,
  shareGameAction,
  getSubCategoryAction,
  getCategoryAction
} from '../../components/actions/Game';

import { getClientsAction } from "../../components/actions/Player";
import { formatAMPM, formartTime } from '../../function';

import '../../scss/dashboard.scss';
import "antd/dist/antd.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const { Text, Title, Link } = Typography;
const { Search, TextArea } = Input;
const { Panel } = Collapse;
const { Step } = Steps;
const { Option } = Select;

const customDot = (dot, { status, index }) => (
  <img src={`/img/icon/step-${status}-b.png`}/>
);

const formLayout = 'vertical';
const formItemLayout =
  formLayout === 'horizontal'
    ? {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 14,
        },
      }
    : null;

export default function GameLibraryPage() {
  const navigate = useNavigate();
  const onSearch = (value) => console.log(value);
  const [modalStep, setModalStep] = useState(0);
  const [verifiedCode, setVerifiedCode] = useState('00000');
  const [mountState, setMountState] = useState(0);
  const [sharedGameList, setSharedGameList] = useState([]);
  const [sharedCompetitionList, setSharedCompetitionList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedSharedClient, setSelectedShareClient] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [shareSubmitState, setShareSubmitState] = useState(1);

  const [topicId, setTopicId] = useState(-1);
  const [editId, setEditId] = useState(-1);
  const [editQuestionTitle, setEditQestionTitle] = useState('');
  const [editCategory, setEditCategory] = useState('');
  const [editSubCategory, setEditSubCategory] = useState('');
  const [editDuration, setEditDuration] = useState(0);
  const [editFirstAnswer, setEditFirstAnswer] = useState('');
  const [editSecondAnswer, setEditSecondAnswer] = useState('');
  const [editThirdAnswer, setEditThirdAnswer] = useState('');
  const [editFourthAnswer, setEditFourthAnswer] = useState('');
  const [quizList, setQuizList] = useState([]);
  const [wholeTime, setWholeTime] = useState('');

  const [categories, setCategories] = useState('');
  const [subCategories, setSubCategories] = useState('');

  useEffect(() => {
    if (!mountState) {
      setMountState(1);
      var event = new Date();
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      setDate(event.toLocaleDateString('es-ES', options));
      setTime(formatAMPM(event));
      const loginInfo = JSON.parse(localStorage.getItem('auth'));
      setUserName(loginInfo.firstName);

      getClientsAction({}, list => {
        let data = list.map((item) => {
          return {
            key: item._id,
            client: item.name,
            state: item.isActive
          }
        });
        setClients(data);
      });

      getSharedGames(list => {
        let gameList = [];
        list.map((item, index) => {
          gameList.push({
            key: index,
            client: item.firstName,
            play: item.topic,
            quiz: item.quizCnt,
            date: new Date(item.createdAt).toLocaleDateString()
          });
        });
        // setSharedGameList(gameList);
      });

      getSharedCompetitions(list => {
        let gameList = [];
        list.map((item) => {
          gameList.push({
            client: item.firstName,
            play: item.competitionName,
            quiz: item.quizCnt,
            date: new Date(item.createdAt).toLocaleDateString()
          });
        });
        // setSharedCompetitionList(gameList);
      });

      getGameLibraryList(list => {
        let gameList = list.map((item) => {
          return{
            key: item._id,
            game: item.name,
            count: item.questions.length,
            questions: item.questions,
            estimate: '111',
          };
        });
        setGameList(gameList);
      });

      getCategoryAction((list) => {
        let categoryList = []
        let firstCategory = ''
        list.map((l, index) => {
          categoryList.push(l.category);
          if (!firstCategory) firstCategory = l.category
        });
        setCategories(categoryList);
      });

      getSubCategoryAction((list) => {
        let subCategoryList = []
        let firstSubCategory = ''
        list.map((l, index) => {
          subCategoryList.push(l.subCategory);
          if (!firstSubCategory) firstSubCategory = l.subCategory
        });
        setSubCategories(subCategoryList);
      });
    }
  });
  const StyledReactInputVerificationCode = styled.div`
       display: flex;
       justify-content: center;

       --ReactInputVerificationCode-itemWidth: 40px;
       --ReactInputVerificationCode-itemHeight: 40px;
       --ReactInputVerificationCode-itemSpacing: 8px;

       .ReactInputVerificationCode__item {
           font-size: 16px;
           font-weight: 500;
           color: #ABB4C5;
           border: 1px solid
           ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
           border-radius: 4px;
           line-height: 40px;
           box-shadow: none;
           background: #fff;
       }

       .ReactInputVerificationCode__item.is-active {
           box-shadow: none;
           border: 1px solid #36c6d9;
       }
       `;
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      let selected = selectedRows.map(row => {
        return row.key;
      });
      setSelectedGames(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const addShareClient = (id) => {
    let exist = false;
    selectedSharedClient.map((shareClientId) => {
      if (shareClientId === id) {
        exist = true;
      }
    });
    if (exist) return;
    setSelectedShareClient([
      ...selectedSharedClient,
      id
    ]);
  }

  const shareGameToClient = () => {
    shareGameAction(selectedGames, selectedSharedClient, () => {
      setShareSubmitState(2);
      // handleShareCancel();
    }); 
  }
  
  const sharedGameColumn = [
    {
      title: 'Clientes',
      dataIndex: 'client',
      key: 'client',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Juego',
      dataIndex: 'play',
      key: 'play',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Preguntas',
      dataIndex: 'quiz',
      key: 'quiz',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Fecha compartido',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    }
  ];

  const sharedChallengeColumn = [
    {
      title: 'Clientes',
      dataIndex: 'client',
      key: 'client',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Juego',
      dataIndex: 'play',
      key: 'play',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Preguntas',
      dataIndex: 'quiz',
      key: 'quiz',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Fecha  inicio',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    }
  ];
  
  const gameListColumn = [
    {
      title: 'Juegos',
      dataIndex: 'game',
      key: 'game',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Preguntas',
      dataIndex: 'count',
      key: 'count',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Tiempo Estimado',
      dataIndex: 'estimate',
      key: 'estimate',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-center'><Button className='bit-button back-yellow hs' onClick={() => editGame(record.key)} type="primary">Resumen</Button></div>
      )
    },
  ];

  
  const questionTableColumn = [
    {
      title: 'Preguntas',
      dataIndex: 'question',
      key: 'question',
      render: (quiz, record) => (
        editId === record.key ?
          <TextArea rows={4} className='hm' value={editQuestionTitle} onChange={(e) => setEditQestionTitle(e.target.value)}/>
          :
          quiz
      )
    },
    {
      title: 'Alternativas',
      dataIndex: 'answers',
      key: 'answers',
      render: (alternative, record) => (
        editId !== record.key ?
          (<div className='flex-column h-start'>
            {alternative.map((alter, index) => (  
              <div className='h-center mt-bit'>
                {alter.state === true ? <CheckCircleFilled style={{color: "#01cf96",fontSize: '20px'}} /> : <CloseCircleFilled style={{color: "#f42b35",fontSize: '20px'}} />}
                <Text className='small-title ml-bit'>{alter.value}</Text>
              </div>
            ))}  
          </div>)
          : 
          (
            (<div className='flex-column h-start'>
            {alternative.map((alter, index) => (  
              <div className='h-center mt-bit'>
                {alter.state === true ? <CheckCircleFilled style={{color: "#01cf96",fontSize: '20px'}} /> : <CloseCircleFilled style={{color: "#f42b35",fontSize: '20px'}} />}
                {
                  index === 0 ? <Input className='hm' value={editFirstAnswer} onChange={(e) => setEditFirstAnswer(e.target.value)}/> :
                  index === 1 ? <Input className='hm' value={editSecondAnswer} onChange={(e) => setEditSecondAnswer(e.target.value)}/> :
                  index === 2 ? <Input className='hm' value={editThirdAnswer} onChange={(e) => setEditThirdAnswer(e.target.value)}/> :
                    <Input className='hm' value={editFourthAnswer} onChange={(e) => setEditFourthAnswer(e.target.value)}/>
                }
              </div>
            ))}  
          </div>)
          )
      )
    },
    {
      title: (<>Categoría/<br/>subcategoría</>),
      dataIndex: 'category',
      key: 'category',
      render: (category, record) => (
        editId === record.key ?
          <>
            <Select
              defaultValue="select"
              style={{
                width: "100%",
              }}
              onChange={((val) => setEditCategory(val))} 
              value={editCategory}
            >
              {
                categories.map((c) => {
                  return <Option value={c}>{c}</Option>
                })
              }
            </Select>
            <Select
              defaultValue="select"
              style={{
                width: "100%",
              }}
              onChange={((val) => setEditSubCategory(val))} 
              value={editSubCategory}
            >
              {
                subCategories.map((s) => {
                  return <Option value={s}>{s}</Option>
                })
              }
            </Select>
          </>
          // <Input className='hm' value={editCategory} onChange={(e) => setEditCategory(e.target.value)}/>
          :
          <>{category}<br/>{record.subCategory}</>
      )
    },
    {
      title: 'Tiempo',
      dataIndex: 'time',
      key: 'time',
      render: (time, record) => (
        editId === record.key ?
          <Select
            defaultValue="select"
            style={{
              width: "100%",
            }}
            onChange={((val) => setEditDuration(val))} 
            value={editDuration}
          >
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
            <Option value={40}>40</Option>
            <Option value={50}>50</Option>
          </Select>
          // <Input className='hm' value={editDuration} onChange={(e) => setEditDuration(e.target.value)}/>
          :
          time
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        editId !== record.key ?
          <div className='v-around'>
            <BiEdit style={{color: "#8146FF",fontSize: '20px'}} onClick={() => editItem(record.key, record)}/>
            <RiDeleteBin6Line style={{color: "#F26E6E",fontSize: '20px'}} onClick={() => removeItem(record.key)}/>
          </div>
          :
          <div className='v-around h-center'>
            <AiOutlineCheckCircle style={{color: "#38DF2A",fontSize: '20px'}} onClick={() => editQuestion()} />
            <RiDeleteBin6Line style={{color: "#F26E6E",fontSize: '20px'}} onClick={() => removeItem(record.key)}/>
          </div>
      )
    },
  ];

  const editItem = (key, record) => {
    setEditId(key);
    setEditCategory(record.category);
    setEditSubCategory(record.subCategory);
    setEditDuration(record.time);
    setEditQestionTitle(record.question);
    /*setEditFirstAnswer(record.answers[0].title);
    setEditSecondAnswer(record.answers[1].title);
    setEditThirdAnswer(record.answers[2].title);
    setEditFourthAnswer(record.answers[3].title);*/
  }

  const editGame = (key) => {
    navigate('/general/edit-question/'+key); // Reemplaza '/ruta' con la ruta a la que deseas redirigir
  };

  const editQuestion = () => {
    let rebuildQuestion = [];
    editQuestionAction(editId, {
      question: editQuestionTitle,
      category: editCategory,
      subCategory: editSubCategory,
      duration: editDuration,
      firstAnswer: editFirstAnswer,
      secondAnswer: editSecondAnswer,
      thirdAnswer: editThirdAnswer,
      fourthAnswer: editFourthAnswer
    }, () => {
      getQuizData(topicId, (list) => {
        let totalTime = 0;
        let tdata = list.map((item, index) => {
          totalTime += parseInt(item.duration);
          return {
            key: item._id,
            question: item.question,
            answers: [
              {
                state: item.firstAnswerAccuracy,
                title: item.firstAnswer
              }, 
              {
                state: item.secondAnswerAccuracy,
                title: item.secondAnswer
              },
              {
                state: item.thirdAnswerAccuracy,
                title: item.thirdAnswer
              },
              {
                state: item.fourthAnswerAccuracy,
                title: item.fourthAnswer
              }
            ],
            category: item.category,
            subCategory: item.subCategory,
            time: item.duration,
          };
        });
        setEditId(-1);
        setQuizList(tdata);
        setWholeTime(totalTime);
        setMountState(0);
      });
    });
  }

  const removeItem = (key) => {
    let rebuildData = [];
    removeQuestionAction(key, (list) => {
      getQuizData(topicId, (list) => {
        let totalTime = 0;
        let tdata = list.map((item, index) => {
          totalTime += parseInt(item.duration);
          return {
            key: item._id,
            question: item.question,
            answers: item.answers,
            category: item.category,
            time: item.duration,
          };
        });
        setQuizList(tdata);
        setEditId(-1);
        setWholeTime(totalTime);
        setMountState(0);
      });
    });
  }

  const removeSharedClient = (removeId) => {
    let clients = [];
    selectedSharedClient.map((id) => {
      if (removeId !== id) {
        clients.push(id);
      }
    })
    console.log(clients);
    setSelectedShareClient(clients);
  }

  const gameDetail = (game) => {
    setTopicId(game._id);
    let totalTime = 0;
    let tdata = game.questions.map((item, index) => {
      totalTime += parseInt(item.duration);
      return {
        key: item._id,
        question: item.question,
        answers: item.answer,
        category: item.category,
        time: item.duration,
      };
    });
    setQuizList(tdata);
    setWholeTime(totalTime);
    setMountState(0);
    showModal();
  }

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const handleCancel = (e) => {
      console.log(e);
      setOpen(false);
  };

  const [openShare, setOpenShare] = useState(false);
  const showShareModal = () => {
    setShareSubmitState(1);
    setSelectedShareClient([]);
    setOpenShare(true);
  };
  const handleShareOk = (e) => {
      setOpenShare(false);
  };
  
  const handleShareCancel = (e) => {
      setMountState(0);
      setOpenShare(false);
  };
  
  return (
    <div>
      <div className='content-h' style={{}}>
          <div className='v-between  w-100 h-end'>
              <Title className='large-title no-margin'>Hola {username},</Title>
              <Text className='smail-title'>{date}</Text>
          </div>
          <div className='v-between  w-100'>
              <Text className='bit-title'>Bienvenido al Dashboard.</Text>
              <Text className='bit-title'>{time}</Text>
          </div>
      </div>
      <div className='dashboard'>
          <div className='borderL mt-medium b-shadow'>
              <div className='game-library-main-header h-center v-between pv-large'>
                  <Text className='medium-title' style={{color: "#6A67CE"}}>BIBLIOTECA DE JUEGOS</Text>
                  <Button className={`bit-button back-blue hs ${selectedGames.length === 0? "back-grey": ""}`} disabled={selectedGames.length === 0? true: false} onClick={() => {showShareModal()}} type="primary">Compartir</Button>
              </div>
              <div className='padding-small' >
                <Table
                    columns={gameListColumn}
                    dataSource={gameList}
                    pagination={false}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    scroll={{
                      y: 350,
                    }}
                  />
              </div>
          </div>
          <div className='mt-medium'>
          <Collapse 
              bordered={false}
              defaultActiveKey={[]}
          >
              <Panel 
                  header="JUEGOS RECIÉN COMPARTIDOS" key="1" 
                  className='collapse-panel mb-large'
                  extra={
                      <div onClick={e => e.stopPropagation()}>
                        <Button size="small"
                            style={{
                                borderRadius: "6px",
                                fontFamily: 'Inter',
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "17px",
                                display: "flex",
                                alignItems: "center",
                                color: "#6A67CE",
                                height: 35,
                            }}
                            className="pv-medium color-g"
                        >
                          Detalles
                        </Button>
                      </div>
                  }
              >
                <Table
                    columns={sharedGameColumn}
                    dataSource={sharedGameList}
                    pagination={false}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                  />
              </Panel>
              <Panel header="DESAFÍOS INICIADOS RECIENTEMENTE" key="2" 
                  className='collapse-panel mb-large panel-h-green'
                  extra={
                      <div onClick={e => e.stopPropagation()}>
                        <Button size="small"
                            style={{
                                borderRadius: "6px",
                                fontFamily: 'Inter',
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "17px",
                                display: "flex",
                                alignItems: "center",
                                color: "#6A67CE",
                                height: 35,
                            }}
                            className="pv-medium color-g"
                        >
                          Detalles
                        </Button>
                      </div>
                  }
              >
                <Table
                    columns={sharedChallengeColumn}
                    dataSource={sharedCompetitionList}
                    pagination={false}
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                  />
              </Panel>
          </Collapse>
          </div>
          
          <Modal
              title=''
              open={open}
              onOk={handleOk}
              onCancel={handleCancel}
              width="70%"
              height="80%"
              style={{
                // top: "0",
                paddingBottom: "0px",
              }}
              footer=''
          >
              <div className='modal-content flex-column v-between h-center' style={{border: "none", height: "100%", padding: "initial"}}>
                <div className='panel mt-medium quiz-list'> 
                    <div className='summary-header-panel v-between pv-medium h-center'>
                      <Text className='summary-title'>Resumen de preguntas</Text>
                      <Text className='summary-text'>Cantidad de preguntas: {quizList.length}</Text>
                      <Text className='summary-text'>Total estimado de juego: {formartTime(wholeTime)}</Text>
                    </div>
                    <Table
                        columns={questionTableColumn}
                        dataSource={quizList}
                        pagination={false}
                    />
                </div>
              </div>
          </Modal>
          <Modal
            title='Compartir juego'
            open={openShare}
            onOk={handleShareOk}
            onCancel={handleShareCancel}
            width="40%"
            // height="60%" 
            style={{
              paddingBottom: "0px",
            }}
            footer=''
            className="business-modal"
          >
            {
              shareSubmitState === 1 ?
               (
                <Form
                  {...formItemLayout}
                  layout={formLayout}
                >
                  <div className='modal-content flex-column v-between h-center' style={{border: "none", height: "100%"}}>
                      <div style={{width: "350px"}}>
                        <Form.Item label='Buscar cliente'>
                          <Select
                            defaultValue="Escribe o elige el nombre del cliente"
                            style={{
                              width: 350,
                            }}
                            onChange={(val) => addShareClient(val)}
                          >
                            {
                              clients.map((c) => {
                                return <Option value={c.key} >
                                  <div className='v-between'>
                                    <span>{c.client}</span>
                                  </div>
                                </Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                        <Form.Item label='Clientes:'>
                          {
                            selectedSharedClient.map((sharedId) => {
                              let clientName = '';
                              clients.map((c) => {
                                if (c.key === sharedId) {
                                  clientName = c.client
                                }
                              });
                              return (
                                <div className='w-100 v-between'>
                                  <span style={{marginLeft: "10px"}}>{clientName}</span>
                                  <RiDeleteBin6Line style={{color: "#F26E6E",fontSize: '15px'}} onClick={() => removeSharedClient(sharedId)}/>
                                </div>
                              )
                            })
                          }
                        </Form.Item>
                      </div>
                      <div className='w-100 v-center mt-large'>
                        <Button className='bit-button hs' onClick={() => handleShareCancel()} type="primary">Volver</Button>
                        <Button className='bit-button hs ml-medium' onClick={() => shareGameToClient()} type="primary">Compartir</Button>
                      </div>
                  </div>
                </Form>
               )
               :
               (
                <div className='w-100'>
                  <div className='w-100 v-center'>
                    <div style={{
                      width: "60%",
                      display: "flex"
                    }}>
                      <div className="v-end">
                        <img src='/img/icon/modal_success.png'/>
                      </div>
                      <div className="flex-column v-center ml-medium">
                        <Text className='medium-title'>Juego compartido con: 
                          {
                            selectedSharedClient.map((sharedId, index) => {console.log(selectedSharedClient);
                              let clientName = ' ';
                              clients.map((c) => {
                                if (c.key === sharedId) {
                                  clientName = c.client
                                }
                              });
                              
                              if (index === selectedSharedClient.length-1) {
                                clientName += "";
                              } else {
                                clientName += ", ";
                              }
                              return (
                                <>{clientName}</>
                              )
                            })
                          }
                        </Text><br/>
                        <Text className='bit-title'>El juego se cargará la Biblioteca de nuestro cliente de forma automática</Text>
                      </div>
                    </div>
                  </div>
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' onClick={() => handleShareCancel()} type="primary">Finalizar</Button>
                  </div>
                </div>
               )
            }
        </Modal>
      </div>
    </div>
  );
}
